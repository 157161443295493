import { z } from "zod";

export type AHBRequestContent = z.infer<typeof AHBRequestPayloadSchema>;
const dateSchema = z.string().regex(/^\d{8}$/); // YYYYMMDD format
const timeSchema = z.string().regex(/^\d{6}$/); // HHMMSS format
const institutionskennzeichenSchema = z.string().length(9);
const postleitzahlSchema = z.string().min(1).max(10);
const stockwerkSchema = z.string().refine(
  (value) => {
    return /^(u|0|h|s|\d{1,2})$/.test(value); // Regex to allow "u", "0", "h", "s" or 1-2 digits
  },
  {
    message:
      'Invalid Stockwerk value. Must be "u", "0", "h", "s", or 1-2 digits.',
  },
);

export const AHBRequestPayloadSchema = z.object({
  Nutzdaten: z.object({
    "@xmlns": z.string(),
    "@xmlns:gdb": z.string(),
    "@xmlns:aar": z.string(),
    "@xmlns:arh": z.string(),
    "@xmlns:feh": z.string(),
    "@xmlns:sto": z.string(),
    Header: z.object({
      "gdb:Absender": z.object({
        "gdb:Krankenhaus": z.object({
          "gdb:Institutionskennzeichen": institutionskennzeichenSchema,
        }),
      }),
      "gdb:Empfaenger": z.object({
        "gdb:Krankenkasse": z.object({
          "gdb:Institutionskennzeichen": institutionskennzeichenSchema,
        }),
      }),
      "gdb:Erstellungsdatum": dateSchema,
      "gdb:Erstellungszeit": timeSchema,
      "gdb:Datei_ID": z.string().uuid(),
      "gdb:Verfahrenskennung": z.string().max(5),
      "gdb:Unterverfahren": z.string().max(3),
      "gdb:Nachrichtentyp": z.string().max(3),
      "gdb:Logische_Version": z.string().max(5),
    }),
    Body: z.object({
      Anschlussrehabilitation: z.object({
        "aar:Antrag": z.object({
          "aar:Versicherter": z.object({
            "gdb:Nachname": z.string().min(1).max(45),
            "gdb:Vorname": z.string().min(1).max(45),
            "gdb:Titel": z.string().max(20).optional(),
            "gdb:Namenszusatz": z.string().max(20).optional(),
            "gdb:Vorsatzwort": z.string().max(20).optional(),
            "gdb:Strasse": z.string().max(46).optional(),
            "gdb:Hausnummer": z.string().max(9).optional(),
            "gdb:Postleitzahl": postleitzahlSchema.optional(),
            "gdb:Ort": z.string().min(1).max(40).optional(),
            "gdb:Anschriftenzusatz": z.string().max(40).optional(),
            "gdb:Laenderkennzeichen": z.string().min(1).max(3).optional(),
            "gdb:Krankenversichertennummer": z.string().min(10).max(12),
            "gdb:Geburtsdatum": dateSchema,
            "gdb:Geschlecht": z.enum(["w", "m", "x"]),
            "gdb:Telefon": z.string().min(1).max(25).optional(),
            "gdb:KH_internes_Kennzeichen": z.string().min(1).max(15),
          }),
          "aar:Antrag": z.object({
            "arh:Verarbeitungskennzeichen": z.union([
              z.literal(10),
              z.literal(21),
            ]),
            "arh:Angaben_des_Versicherten": z.object({
              "arh:Antrag_Pflegebeduerftigkeit": z.enum(["0", "1"]),
              "arh:Rentenantragstellung": z.object({
                "arh:Altersrente": z.enum(["0", "1"]),
                "arh:Traeger_der_Altersrente": z.string().max(150).optional(),
                "arh:Erwerbsminderungsrente": z.enum(["0", "1"]),
                "arh:Traeger_der_Erwerbsminderungsrente": z
                  .string()
                  .max(150)
                  .optional(),
              }),
              "arh:Haeusliche_Situation": z.object({
                "arh:Stockwerk": stockwerkSchema,
                "arh:Aufzug": z.enum(["0", "1"]),
                "arh:Besonderheiten": z.string().min(1).max(600).optional(),
              }),
              "arh:Haeusliche_Versorgungssituation": z.object({
                "arh:Wohnsituation": z.enum(["1", "2", "3", "4"]),
                "arh:Versorgung_Sichergestellt": z.enum(["0", "1"]),
              }),
              "arh:Hausarzt": z
                .object({
                  "arh:Nachname": z.string().max(45),
                  "arh:Vorname": z.string().max(45),
                  "arh:Strasse": z.string().max(46).optional(),
                  "arh:Hausnummer": z.string().min(1).max(9).optional(),
                  "arh:Postleitzahl": postleitzahlSchema.optional(),
                  "arh:Ort": z.string().min(1).max(40),
                  "arh:Telefon": z.string().max(25).optional(),
                })
                .optional(),
              "arh:Betreuung_Vollmacht_Vertreter": z
                .array(
                  z.object({
                    "arh:Kennzeichen_Vertretung": z.union([
                      z.literal(1),
                      z.literal(2),
                      z.literal(3),
                    ]),
                    "arh:Nachname": z.string().min(1).max(45),
                    "arh:Vorname": z.string().min(1).max(45),
                    "arh:Strasse": z.string().min(1).max(46).optional(),
                    "arh:Hausnummer": z.string().min(1).max(9).optional(),
                    "arh:Postleitzahl": postleitzahlSchema.optional(),
                    "arh:Ort": z.string().min(1).max(40).optional(),
                    "arh:Telefon": z.string().min(1).max(25).optional(),
                  }),
                )
                .max(2)
                .optional(),
              "arh:Anforderungen_Wuensche": z.string().max(600).optional(),
            }),
            "arh:Willenserklaerung_Unterschrift": z.enum(["1"]),
            "arh:Entbindung_Schweigepflicht": z.enum(["0", "1"]),
            "arh:Einwilligung_Uebermittlung": z.enum(["1"]),
            "arh:Angaben_des_Krankenhauses": z.object({
              "arh:Standortnummer": z.string().length(9),
              "arh:Ansprechpartner": z.object({
                "arh:Nachname": z.string().min(1).max(45),
                "arh:Vorname": z.string().min(1).max(45).optional(),
                "arh:Telefon": z.string().min(1).max(25),
                "arh:Fax": z.string().min(1).max(25).optional(),
              }),
              "arh:Kommunikation": z.object({
                "arh:Deutsche_Sprache": z.enum(["0", "1"]),
                "arh:Sprache": z.string().max(150).optional(),
              }),
              "arh:Rehaeinrichtung": z
                .object({
                  "arh:Rehaeinrichtung_nicht_angefragt": z
                    .literal(1)
                    .optional(),
                  "arh:Angefragte_Rehaeinrichtung": z
                    .object({
                      "arh:Institutionskennzeichen": z
                        .string()
                        .min(1)
                        .max(9)
                        .optional(),
                      "arh:Anschrift_Rehaeinrichtung": z
                        .object({
                          "arh:Name": z.string().min(1).max(45),
                          "arh:Strasse": z.string().min(1).max(46),
                          "arh:Hausnummer": z.string().min(1).max(9),
                          "arh:Postleitzahl": z.string().min(1).max(10),
                          "arh:Ort": z.string().min(1).max(40),
                          "arh:Telefon": z.string().min(1).max(25),
                        })
                        .optional(),
                    })
                    .optional(),
                  "arh:Fruehestmoegliche_Aufnahme": z.string().length(8), // YYYYMMDD
                })
                .superRefine((val, ctx) => {
                  const hasNichtAngefragt =
                    val["arh:Rehaeinrichtung_nicht_angefragt"] === 1;
                  const angefragt = val["arh:Angefragte_Rehaeinrichtung"];

                  // Rule: Either nicht_angefragt or angefragt – but not both or neither
                  if (hasNichtAngefragt && angefragt) {
                    ctx.addIssue({
                      path: ["arh:Rehaeinrichtung_nicht_angefragt"],
                      code: z.ZodIssueCode.custom,
                      message:
                        "Entweder 'Rehaeinrichtung_nicht_angefragt' oder 'Angefragte_Rehaeinrichtung' darf gesetzt sein – nicht beides.",
                    });
                  }
                  if (!hasNichtAngefragt && !angefragt) {
                    ctx.addIssue({
                      path: ["arh:Angefragte_Rehaeinrichtung"],
                      code: z.ZodIssueCode.custom,
                      message:
                        "Es muss entweder 'Rehaeinrichtung_nicht_angefragt' oder 'Angefragte_Rehaeinrichtung' gesetzt sein.",
                    });
                  }

                  // Rule: If angefragt exists, it must contain either IK or Anschrift
                  if (angefragt) {
                    const hasIK = !!angefragt["arh:Institutionskennzeichen"];
                    const hasAnschrift =
                      !!angefragt["arh:Anschrift_Rehaeinrichtung"];

                    if (!hasIK && !hasAnschrift) {
                      ctx.addIssue({
                        path: ["arh:Angefragte_Rehaeinrichtung"],
                        code: z.ZodIssueCode.custom,
                        message:
                          "'Angefragte_Rehaeinrichtung' muss entweder 'Institutionskennzeichen' oder 'Anschrift_Rehaeinrichtung' enthalten.",
                      });
                    }

                    if (hasIK && hasAnschrift) {
                      ctx.addIssue({
                        path: ["arh:Angefragte_Rehaeinrichtung"],
                        code: z.ZodIssueCode.custom,
                        message:
                          "'Angefragte_Rehaeinrichtung' darf nicht gleichzeitig 'Institutionskennzeichen' und 'Anschrift_Rehaeinrichtung' enthalten.",
                      });
                    }
                  }
                }),
              "arh:Krankenhausbehandlung": z.object({
                "arh:Beginn": dateSchema,
                "arh:Ende": dateSchema,
                "arh:Version_ICD_OPS": z.string().length(4),
              }),
            }),
            "arh:Fruehrehabilitation": z.enum(["0", "1"]),
            "arh:Verlegung": z.object({
              "arh:Kennzeichen_Verlegung": z.union([
                z.literal(1),
                z.literal(2),
                z.literal(3),
              ]),
              "arh:Datum_Verlegung": dateSchema.optional(),
              "arh:Begruendung": z.string().max(600).optional(),
            }),
            "arh:Funktionsdiagnosen": z.object({
              "arh:Funktionsdiagnose": z
                .array(
                  z.object({
                    "arh:Lfd_Nr": z.enum(["1", "2", "3", "4", "5"]),
                    "arh:Diagnose": z.string().min(1).max(150),
                    "arh:ICD_10_GM": z.string().min(3).max(6), // ICD-10 codes are typically 3–5, plus 1 for subcodes or dots
                    "arh:Ursache": z.enum(["0", "1", "2", "3", "4", "5"]),
                  }),
                )
                .min(1)
                .max(5),
            }),
            "arh:Behandlungen": z.object({
              "arh:Operation_durchgefuehrt": z.enum(["0", "1"]),
              "arh:Operationen": z.object({
                "arh:Operation": z
                  .array(
                    z.object({
                      "arh:Wundheilung": z.enum(["0", "1"]),
                      "arh:OPS_Codes": z.object({
                        "arh:OPS": z.array(z.string().min(1).max(8)),
                      }),
                      "arh:OP_Datum": dateSchema,
                    }),
                  )
                  .min(1)
                  .max(5),
              }),
              "arh:Andere_Behandlung": z.string().min(1).max(150).optional(),
            }),
            "arh:Komplikationen": z.object({
              "arh:Komplikationen_aufgetreten": z.enum(["0", "1"]),
              "arh:Art_der_Komplikation": z
                .object({
                  "arh:Komplikation_mit_AR_Diagnose": z
                    .string()
                    .min(1)
                    .max(150)
                    .optional(),
                  "arh:Kardiovaskulaere_Komplikation": z
                    .string()
                    .min(1)
                    .max(150)
                    .optional(),
                  "arh:Sonstige_Komplikation": z
                    .string()
                    .min(1)
                    .max(150)
                    .optional(),
                })
                .optional(),
              "arh:Keime": z.object({
                "arh:Keimbesiedelung": z.enum(["0", "1", "2"]),
                "arh:Art_der_Keime": z.string().min(1).max(150).optional(),
              }),
            }),
            "arh:Alltagsrelevante_Beeintraechtigungen": z.object({
              "arh:Beeintraechtigung": z
                .array(
                  z.object({
                    "arh:Item": z.string().length(2),
                    "arh:Punkte": z.number().int().min(0).max(5),
                  }),
                )
                .max(20),
              "arh:Gesamtpunkte": z.number().int().min(0).max(100),
            }),
            "arh:Rehabilitationsfaehigkeit": z
              .object({
                "arh:Ausreichende_Therapiebelastbarkeit": z.enum(["0", "1"]),
                "arh:Belastbarkeit_nach_OP": z.enum(["1", "2", "3"]).optional(),
                "arh:Teilbelastbar_mit": z
                  .number()
                  .int()
                  .min(1)
                  .max(999)
                  .optional(),
                "arh:Anpassung_Interimsprothese_erfolgt": z.enum(["0", "1"]),
                "arh:Besonderheiten": z.string().max(150).optional(),
                "arh:Kriterien_FR_Index": z
                  .object({
                    "arh:Kriterium": z
                      .array(z.number().int().min(1).max(7))
                      .min(1)
                      .max(7),
                  })
                  .optional(),
              })
              .superRefine((data, ctx) => {
                // If Belastbarkeit_nach_OP is "2", Teilbelastbar_mit must be provided
                if (data["arh:Belastbarkeit_nach_OP"] === "2") {
                  if (data["arh:Teilbelastbar_mit"] === undefined) {
                    ctx.addIssue({
                      path: ["arh:Teilbelastbar_mit"],
                      code: z.ZodIssueCode.custom,
                      message:
                        '"Teilbelastbar_mit" is required when Belastbarkeit_nach_OP is "2" (teilweise belastbar)',
                    });
                  }
                }
              }),
            "arh:Rehabilitationsziel": z.string().min(1).max(600),
            "arh:Zusammenfassende_Bewertung": z.object({
              "arh:Schwerpunkt": z
                .object({
                  "arh:Rehaschwerpunkt": z.string().regex(/^\d{2}$/),
                  "arh:Erlaeuterung": z.string().min(1).max(600).optional(),
                })
                .refine(
                  (data) =>
                    data["arh:Rehaschwerpunkt"] !== "21" ||
                    !!data["arh:Erlaeuterung"],
                  {
                    message: `"arh:Erlaeuterung" is required when "arh:Rehaschwerpunkt" is "21"`,
                    path: ["arh:Erlaeuterung"],
                  },
                ),
              "arh:Empfohlene_Rehabilitationsart": z.union([
                z.literal(1),
                z.literal(2),
                z.literal(4),
              ]),
              "arh:Begruendung_Rehabilitationsart": z
                .string()
                .min(1)
                .max(150)
                .optional(),
              "arh:Fall_Paragraph_16": z.union([z.literal(1), z.literal(2)]),
              "arh:Begruendungen": z
                .object({
                  "arh:Begruendung": z
                    .array(
                      z.string().regex(/^\d{2}$/, {
                        message: "Must be a 2-digit code",
                      }),
                    )
                    .min(1)
                    .max(12),
                })
                .optional(),
            }),
            "arh:Weitere_Informationen": z.object({
              "arh:Besondere_Anforderungen": z.object({
                "arh:Keine_besonderen_Anforderungen": z.literal("1").optional(),
                "arh:Anforderungen": z
                  .object({
                    "arh:Anforderung": z
                      .array(
                        z.string().regex(/^(0[1-9]|1[0-7])$/, {
                          message: "Must be a number between 01 and 17",
                        }),
                      )
                      .min(1)
                      .max(17),
                    "arh:Dialyseverfahren": z.string().max(150).optional(),
                    "arh:Erlaeuterung": z.string().max(600).optional(),
                    "arh:Pflegegrad_Angehoeriger": z
                      .enum(["1", "2", "3", "4", "5"])
                      .optional(),
                    "arh:Grad_Dekubitus": z
                      .enum(["1", "2", "3", "4"])
                      .optional(),
                    "arh:Last_bis": z.number().int().min(1).max(999).optional(),
                  })
                  .optional(),
              }),
              "arh:Anreise": z.object({
                "arh:Verkehrsmittel": z.enum([
                  "01", // öffentliches Verkehrsmittel
                  "02", // Pkw
                  "03", // Taxi/Mietwagen
                  "04", // Taxi/Mietwagen, Rollstuhl
                  "05", // Taxi/Mietwagen, Tragestuhl
                  "06", // Taxi/Mietwagen, liegend
                  "07", // Krankentransport, Rollstuhl
                  "08", // Krankentransport, Tragestuhl
                  "09", // Krankentransport, liegend
                ]),
                "arh:Begruendung_KTW": z.string().max(150).optional(),
                "arh:Begleitperson": z.enum(["0", "1"]),
              }),
              "arh:Krankenhausarzt": z.object({
                "arh:Nachname": z.string().min(1).max(45),
                "arh:Vorname": z.string().min(1).max(45),
                "arh:Titel": z.string().min(1).max(20).optional(),
                "arh:Telefon": z.string().min(1).max(25),
                "arh:Datum": dateSchema,
              }),
            }),
          }),
        }),
      }),
    }),
  }),
});
